import { Grid, Typography, Box } from "@mui/material";
import React from "react";
import WhyUsImg from "../../../assets/images/whyus.png";

const Whyus = () => {
  return (
    <Box
      sx={{
        px: { xs: 2, sm: 3, md: 5, lg: 8 },
        maxWidth: "1600px",
        mx: "auto",
      }}
    >
      <Grid container spacing={4}>
        {/* Text Section */}
        <Grid item xs={12} md={8}>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: "36px", sm: "48px", md: "60px", lg: "60px" },
              fontFamily: "Playfair Display",
              fontWeight: "500",
            }}
          >
            A Choice that makes
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: "36px", sm: "48px", md: "60px", lg: "60px" },
              fontFamily: "Playfair Display",
              fontWeight: "500",
            }}
          >
            a <span style={{ color: "#D2996C" }}>Difference</span> and
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: "36px", sm: "48px", md: "60px", lg: "60px" },
              fontFamily: "Playfair Display",
              fontWeight: "500",
            }}
          >
            builds <span style={{ color: "#D2996C" }}>Trust</span>
          </Typography>

          {/* Adding the 4 square boxes in 2 rows with space between image */}
          <Box sx={{ mt: 3 }}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  border: "2px solid #EAECE9",
                }}
              >
                <Box
                  sx={{
                    height: "200px", // Adjust box height
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",

                    bgcolor: "#D2996C",
                    color: "#ffffff",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: { xs: "24px", md: "32px" },
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                    }}
                  >
                    Professional Touch
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: { xs: "16px", md: "18px" },
                      fontFamily: "DM Sans",
                      fontWeight: 400,
                    }}
                  >
                    We use our experience in fashion photography - from which we
                    still benefit in terms of professional knowledge,
                    preparation, composition, guidance, and approach.
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  border: "2px solid #EAECE9",
                }}
              >
                <Box
                  sx={{
                    height: "200px", // Adjust box height
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: { xs: "24px", md: "32px" },
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                    }}
                  >
                    Creative Process
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: { xs: "16px", md: "18px" },
                      fontFamily: "DM Sans",
                      fontWeight: 400,
                    }}
                  >
                    We adapt creative process, photography experiences, or pick
                    up some tips we have learned through the years.
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  border: "2px solid #EAECE9",
                }}
              >
                <Box
                  sx={{
                    height: "200px", // Adjust box height
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: { xs: "24px", md: "32px" },
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                    }}
                  >
                    Distinctive & original
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: { xs: "16px", md: "18px" },
                      fontFamily: "DM Sans",
                      fontWeight: 400,
                    }}
                  >
                    Accessible with an artistic touch: A unique and creative
                    approach, with stylish photos that perfectly match you or
                    your company.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ border: "2px solid #EAECE9" }}>
                <Box
                  sx={{
                    height: "200px", // Adjust box height
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",

                    bgcolor: "#D2996C",
                    color: "#ffffff",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: { xs: "24px", md: "32px" },
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                    }}
                  >
                    Fast Delivery
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: { xs: "16px", md: "18px" },
                      fontFamily: "DM Sans",
                      fontWeight: 400,
                    }}
                  >
                    We maintain a sophisticated balance between photoshoot days
                    and office days, which prevents us from having a build-up of
                    post-processed images.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* Image Section */}
        <Grid item xs={12} md={4}>
          <Box sx={{ ml: { xs: 0, md: 4 }, mt: { xs: 4, md: 0 } }}>
            {/* Added margin-left to add spacing between the boxes and the image */}
            <img src={WhyUsImg} alt="Why Us" style={{ width: "100%",height:"650px" }} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Whyus;
