import React from 'react';
import Button from '@mui/material/Button';

const PackagesButton = ({ onClick }) => {
  return (
    <Button
      variant="outlined"
      size="large"
      onClick={onClick} // Add onClick prop
      sx={{
        fontFamily: "DM Sans",
        fontSize: "20px",
        fontWeight: 500,
        color: "#000",
        padding: "10px 30px",
        border: "1px solid #000",
        borderRadius: "0px", 
        textTransform: "none",
        transition: "background-color 0.3s, color 0.3s, transform 0.3s, box-shadow 0.3s", 
        boxShadow: "none", 

        "&:hover": {
          bgcolor: "#fff", 
          color: "#000000",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)", 
          transform: "translateY(-2px)", 
        },
        "&:active": {
          transform: "translateY(1px)", 
          boxShadow: "none", 
        },
      }}
    >
     Packages
    </Button>
  );
};

export default PackagesButton;
