import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ViewGallary = () => {
  const navigate = useNavigate();

  const handleViewGallary = () => {
    navigate("/ecommerce");
  };
  return (
    <Button
      variant="outlined"
      onClick={handleViewGallary}
      size="small"
      sx={{
        fontFamily: "DM Sans",
        fontWeight: 500,
        fontSize: { xs: '16px', sm: '16px', md: '16px' }, 
        border: "3px solid #000",
        color: "#000",
        padding: { xs: '12px 30px', sm: '12px 30px', md: '5px 40px' }, 
        textTransform: "none",
        borderRadius: "0px",
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
        transition: "background-color 0.3s, transform 0.3s",
        "&:hover": {
          bgcolor: "#fff",
          transform: "translateY(-2px)",
        },
        "&:focus": {
          outline: "none",
        },
        "&:active": {
          transform: "translateY(1px)",
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      Visit Gallery
    </Button>
  );
};

export default ViewGallary;
