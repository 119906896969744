import React from "react";
import Grid from "@mui/material/Grid";
import { Typography, Box, Button } from "@mui/material";
import FoodPhotography from "../../../assets/images/requirement/foodPhotography.png";
import EventPhotography from "../../../assets/images/requirement/eventPhotography.png";
import ArchitecturalPhotography from "../../../assets/images/requirement/architecturalPhotography.png";
import SportsPhotography from "../../../assets/images/requirement/sportsPhotography.png";
import EnquiryKnow from "../../Buttons/EnquiryKnow";

const Requirement = () => {
  return (
    <Box
      sx={{
        px: { xs: 2, sm: 3, md: 8, lg: 8 },
        maxWidth: "1600px",
        mx: "auto",
        py: { xs: 4, md: 8 },
        bgcolor: "#f5f5f5", // Light background color for a modern feel
      }}
    >
      <Grid container spacing={4}>
        {/* Text Section */}
        <Grid item xs={12} md={5} sm={6}>
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Playfair Display",
              fontWeight: 600,
              fontSize: { xs: "50px", sm: "60px", md: "74px", lg: "74px" }, // Responsive font size
              color: "#333", // Darker color for better contrast
             
              width: "80%",
            }}
          >
            Contact us for Other Requirements
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "DM Sans",
              fontWeight: 400,
              fontSize: { xs: "16px", md: "16px", lg: "16px" }, // Responsive font size
              marginBottom: "30px",
              color: "#555", // Subtle text color
            }}
          >
            We do photoshoots with nice people at a beautiful location, filled
            with laughter and, of course, amazing results!
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <EnquiryKnow />
            <Box
              component="img"
              src={FoodPhotography}
              alt="food photography"
              sx={{
                maxWidth: { xs: "60px", sm: "80px", md: "300px" }, // Responsive image size
                height: "auto",
                borderRadius: "8px", // Rounded corners for a polished look
                boxShadow: 3, // Subtle shadow for depth
              }}
            />
          </Box>
        </Grid>

        {/* First Column */}
        <Grid item xs={12} sm={6} md={4}>
          <Box
            component="img"
            src={EventPhotography}
            alt="event photography"
            sx={{
              width: "100%",
              height: "80%",
              borderRadius: "8px",
              boxShadow: 3, // Subtle shadow for depth
              transition: "transform 0.3s ease", // Add smooth hover effect
              "&:hover": {
                transform: "scale(1.05)", // Scale up on hover for effect
              },
            }}
          />
        </Grid>

        {/* Second Column */}
        <Grid item xs={12} sm={6} md={3}>
          <Box
            component="img"
            src={ArchitecturalPhotography}
            alt="architectural photography"
            sx={{
              width: "80%",
              height: "auto",

              boxShadow: 3, // Subtle shadow for depth
              marginBottom: { xs: 2, sm: 4, md: 1 }, // Add some margin between images
              transition: "transform 0.3s ease",
              "&:hover": {
                transform: "scale(1.05)", // Scale up on hover for effect
              },
            }}
          />
          <Box
            component="img"
            src={SportsPhotography}
            alt="sports photography"
            sx={{
              width: "80%",

              height: "40%",

              boxShadow: 3, // Subtle shadow for depth
              transition: "transform 0.3s ease",
              "&:hover": {
                transform: "scale(1.05)", // Scale up on hover for effect
              },
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Requirement;
