import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import IconicImg from "../../../assets/images/iconic.png";
import FashionSales from "../../../assets/images/fashionSales.png";
import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ViewGallary from "../../Buttons/ViewGallary";

const GalleryVisit = () => {
  return (
    <Box
      sx={{
        px: { xs: 2, sm: 3, md: 5, lg: 8 },
        maxWidth: "1600px",
        mx: "auto",
        py: 4,
      }}
    >
      <Grid container spacing={6}>
        <Grid item xs={12} md={4}>
          <Typography
            variant="h2"
            gutterBottom
            sx={{
              fontFamily: "Playfair Display",
              fontWeight: 700,
              fontSize: { xs: "36px", md: "84px" },
              marginBottom: 1,
            }}
          >
            Explore Our Featured Works
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mt: 2,
              fontFamily: "DM Sans",
              fontWeight: 400,
              fontSize: { xs: "16px", md: "20px" },
              lineHeight: 1.6,
              mb:"60px"
            }}
          >
            Looking forward to making your vision come to life. View our Gallery
            to see our new collections.
          </Typography>
         <ViewGallary/>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box
            component="img"
            src={IconicImg}
            alt="Iconic Image"
            sx={{ width: "100%", borderRadius: 2, boxShadow: 2 }}
          />
          <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
            <Typography
              sx={{
                fontFamily: "DM Sans",
                fontWeight: 500,
                fontSize: { xs: "24px", md: "36px" },
                flexGrow: 1,
              }}
            >
              Myntra Pink Sale Collections
            </Typography>
            <ArrowForwardIcon sx={{ fontSize: { xs: "24px", md: "36px" }, color: "#000" }} />
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box
            component="img"
            src={FashionSales}
            alt="Fashion Sales"
            sx={{ width: "100%", borderRadius: 2, boxShadow: 2 }}
          />
          <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
            <Typography
              sx={{
                fontFamily: "DM Sans",
                fontWeight: 500,
                fontSize: { xs: "24px", md: "36px" },
                flexGrow: 1,
              }}
            >
              Adidas Exclusive Collections
            </Typography>
            <ArrowForwardIcon sx={{ fontSize: { xs: "24px", md: "36px" }, color: "#000" }} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GalleryVisit;
