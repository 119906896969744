import Navbar from "./Header/Navbar";
import About from "./pages/About/About";
import Home from "./pages/Home/Home";
import "./App.css";
import Clients from "./components/layouts/Clients/Clients";
import Whyus from "./components/layouts/Whyus/Whyus";
import FeaturesOn from "./components/layouts/Features/FeaturesOn";
import Testimonials from "./components/layouts/Testimonials/Testimonials";
import Offers from "./components/layouts/Offers/Offers";
import GalleryVisit from "./components/layouts/Gallery/GalleryVisit";
import Services from "./components/layouts/Services/Services";
import Package from "./pages/Packages/Package";
import Contact from "./components/layouts/Contact/Contact";
import OurTeam from "./pages/OurTeam/OurTeam";
import Requirement from "./components/layouts/Requirement/Requirement";
import Footer from "./components/layouts/Footer/Footer";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import EcommercePremium from "./components/layouts/Workbook/EcommercePremium";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <Navbar />
          <Home />
          <Services />
          <About />
          <Whyus />
          <Clients />
          <FeaturesOn />
          <Testimonials />
          <Offers />
          <GalleryVisit />
          <Requirement />
          <Contact />
          <Footer />
        
        </>
      ),
    },

    {
      path: "/packages",
      element: (
        <>
          <Navbar />
          <Package />
      
          <Footer />
        </>
      ),
    },

    {
      path: "/team",
      element: (
        <>
          <Navbar />
          <OurTeam />
          <Footer />
        </>
      ),
    },
    {
      path: "/contactSection",
      element: (
        <>
          <Navbar />
          <Contact />
          <Footer />
        </>
      ),
    },

    {
      path: "/ecommerce",
      element: (
        <>
        <EcommercePremium/> 
        </>
      )
    },
  
  ]);
  return <RouterProvider router={router} />;
}

export default App;
