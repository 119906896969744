import { Typography, Box, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";
import TestimonialsImg from "../../../assets/images/testimonials.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Testimonials = () => {
  return (
    <Box
      sx={{
        px: { xs: 2, sm: 3, md: 5, lg: 8 },
        maxWidth: "1600px",
        mx: "auto",
      }}
    >
      <Typography
        variant="h2"
        sx={{
          fontSize: { xs: "48px", md: "84px" }, // Responsive font size
          fontFamily: "Playfair Display",
          fontWeight: 500,
          mb: 4,
        }}
      >
        Testimonials
      </Typography>
      <Grid container spacing={8} alignItems="center">
        <Grid item md={6} xs={12}>
          <img
            src={TestimonialsImg}
            alt="Testimonials"
            style={{
              width: "100%",

              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "DM Sans",
              fontWeight: 500,
              fontSize: { xs: "24px", md: "36px" },
              mb: 2,
            }}
          >
            Emily Cooper
          </Typography>
          <Typography
            sx={{
              mt: 2,
              color: "#747576",
              fontFamily: "DM Sans",
              fontWeight: 400,
              fontSize: { xs: "16px", md: "24px" },
              lineHeight: 1.5,
            }}
          >
            I am so pleased with these headshots. I can’t tell you how nice it
            is to see some photos of myself looking happy and normal! I am
            absolutely thrilled to have some photos that I can share with my
            children and use for my website.
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 4,
              fontSize: { xs: "16px", md: "20px" },
              color: "#747576",
            }}
          >
            <IconButton aria-label="previous" size="large">
              <ArrowBackIcon fontSize="inherit" />
            </IconButton>
            <Typography variant="body1" sx={{ mx: 2 }}>
              1 / 3
            </Typography>
            <IconButton aria-label="next" size="large">
              <ArrowForwardIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Testimonials;
