import React from "react";
import { Typography, Box, Paper, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import Magnize from "../../../assets/images/magzine.png";
import Mic from "../../../assets/images/mic.png";
import Podcast from "../../../assets/images/podcast.png";
import Raymond from "../../../assets/images/Raymond.png";
import Camerapic from "../../../assets/images/camera.png";

const FeaturesOn = () => {
  return (
    <Box
      sx={{
        px: { xs: 2, sm: 3, md: 5, lg: 8 },
        maxWidth: "1600px",
        mx: "auto",
        bgcolor: "#EAECE9",
        py: 4,
      }}
    >
      <Typography
        variant="h2"
        sx={{
          fontFamily: "Playfair Display",
          fontWeight: 500,
          fontSize: { xs: "48px", md: "60px" },
          marginBottom: 4,
        }}
      >
        We have been Featured in
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Box>
            <img
              src={Magnize}
              alt="Magazine"
              style={{ width: "100%", height: "auto" }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 2,
                gap:"10px"
              }}
            >
              <Box
                sx={{
      
                  width:"197px",
                  height:"197px",
                 bgcolor:"#fff",
          
                 textAlign:"center"
                }}
              >
                <img
                  src={Podcast}
                  alt="Podcast"
                  style={{ width: "100%",maxWidth:"100px"}}
                />
                <Typography variant="h6" sx={{ marginTop: 1 }}>
                  CDF Podcast
                </Typography>
              </Box>

              <Box
                sx={{
                  width:"197px",
                  height:"197px",
                 bgcolor:"#fff",
                 textAlign:"center",
                 alignItems:"flex-end",
               
                }}
              >
                <img
                  src={Mic}
                  alt="Mic"
                  style={{ width: "100%",maxWidth:"150px"}}
                />
                
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 1,
            }}
          >
            <img
              src={Raymond}
              alt=""
              style={{ width: "100%", height: "auto" }}
            />
            <Button
              variant="contained"
              color="inherit"
              sx={{
                padding:"15px",
                   fontFamily: 'DM Sans',
                  fontWeight: 500,
                  fontSize: "20px",
                  bgcolor: "#000000", 
                  color: "#ffffff", 
                  width: "100%" }}
            >
              Daily Dose Magazine Campaign
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Paper
              sx={{
                bgcolor: "#ffffff",
                p: 2,
                height: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6">Latest Work with LooksNow</Typography>
            </Paper>
            <Paper
              sx={{
                bgcolor: "#ffffff",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" 
              sx={{
                fontFamily: 'DM Sans',
                fontWeight: 500,
                fontSize: "36px",
              }}>News Paper Post</Typography>
              <Typography variant="body1"   
              sx={{ 
              padding:"10px 20px",
                fontFamily: 'DM Sans',
                fontWeight: 400,
                fontSize: "20px",
                }}>
                Lorem ipsum dolor sit amet consectetur. At quis neque ut
                vulputate ultrices sagittis porttitor ornare.{" "}
              </Typography>
              <img
                src={Camerapic}
                alt=""
                style={{ width: "100%", height: "auto" }}
              />
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FeaturesOn;
