import React from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  Typography,
} from "@mui/material";
import EcommerceBasic from "../../assets/images/packages/ecommerceBasic.png";
import EcommercePremium from "../../assets/images/packages/ecommercePremium.png";
import EcommerceOutdoor from "../../assets/images/packages/ecommerceOutdoor.png";
import CorporatePhotoshoot from "../../assets/images/packages/corporatePhotoshoot.png";
import ProductPhotoshoot from "../../assets/images/packages/productPhotoshoot.png";
import CampaignPhotoshoot from "../../assets/images/packages/campaignPhotoshoot.png";
import ViewGallary from "../../components/Buttons/ViewGallary";
import EcommerceVideoOutdoor from "../../assets/images/packages/ecomercevideooutdoor.png";
import IndoorVideoBasic from "../../assets/images/packages/indoorvideobasic.png";
import IndoorVideoPremium from "../../assets/images/packages/indoorvideopremium.png";
import EcomerceTvads from "../../assets/images/packages/ecomercetvads.png";
import InteriorsShoot from "../../assets/images/packages/interiorshoot.png";
import SocialMediaHandling from "../../assets/images/packages/socialmediahandling.png";
import EcommerceCustomisedVideos from "../../assets/images/packages/ecommercecustomised.png";
import { useNavigate } from "react-router-dom";

const cardDataBlack = [
  {
    title: "Ecommerce Photos Basic",
    price: "₹333/-",
    image: EcommerceBasic,
    features: [
      "1 Product, 8 Pictures",
      "International Model",
      "Shoot According to your Marketplace requirement",
      "Backdrop – White/Grey",
      "Basic Props & Accessories",
    ],
  },
  {
    title: "Ecommerce Photos Premium",
    price: "₹599/-",
    image: EcommercePremium,
    features: [
      "1 Product",
      "12 Pictures",
      "International Model",
      "Shoot According to your Marketplace requirement",
      "Backdrop - White/Grey/Sky Blue/Red",
      "Premium Props & Accessories",
      "Premium Lighting setups",
    ],
  },
  {
    title: "Ecommerce Photos Outdoor",
    price: "₹899/-",
    image: EcommerceOutdoor,
    features: [
      "1 Product",
      "8 Pictures",
      "International Model",
      "Shoot According to your Marketplace requirement",
    ],
  },
  {
    title: "Ecommerce Video Outdoor",
    price: "₹1999/-",
    image: EcommerceVideoOutdoor,
    features: [
      "1 Product",
      "Duration : 40sec max",
      "International Model",
      "Shoot According to your Marketplace requirement",
      "Accessories",
    ],
  },
  {
    title: "Indoor Video Basic",
    price: "₹899/-",
    image: IndoorVideoBasic,
    features: [
      "1 Product",
      "1 Video",
      "Duration : 40sec max",
      "International Model",
      "Shoot According to your Marketplace requirement",
      "Backdrop – White/Grey",
      "Basic Props & Accessories",
    ],
  },
  {
    title: "Indoor Video Premium",
    price: "₹1599/-",
    image: IndoorVideoPremium,
    features: [
      "1 Product",
      "1 Video",
      "Duration : 40sec max",
      "International Model",
      "Shoot According to your Marketplace requirement",
      "Backdrop – White/Grey/Beige/red",
      "Premium Props & Accessories",
      "Premium Lighting setups",
    ],
  },
  {
    title: "Corporate Shoot",
    price: "₹7999/-",
    image: CorporatePhotoshoot,
    features: [
      " Backdrop - White/Grey/Sky Blue/Red",
      "Premium Props & Accessories",
      "Premium Lighting setups",
    ],
  },
  {
    title: "Campaign Photo & Video",
    price: "₹75000/-",
    image: CampaignPhotoshoot,
    features: [
      "Shoot According to your Marketplace requirement",
      "Price may vary as per requirement",
    ],
  },
  {
    title: "Commercial TV Ads.",
    price: "₹49999/-",
    image: EcomerceTvads,
    features: [
      "Shoot According to your Marketplace requirement",
      "Price may vary as per requirement",
    ],
  },

  {
    title: "Interior Shoot",
    price: "Contact for details",
    image: InteriorsShoot,
    features: ["Shoot According to your Marketplace requirement"],
  },
  {
    title: "Social Media Handling",
    price: "₹49000/-",
    image: SocialMediaHandling,
    features: ["Banners /Brand Picture", "4 Reels", "4 BTS"],
  },
  {
    title: "Ecommerce Customised Photos /Videos",
    price: "",
    image: EcommerceCustomisedVideos,
    features: [
      "Price varies based on number of products and as per the requirement",
    ],
  },
];


const Packages = () => {
  
  return (
    <section id="packages">
      <Box>
        <Box
          sx={{
            px: { xs: 2, sm: 3, md: 5, lg: 8 },
            maxWidth: "1600px",
            mx: "auto",
            py: 2,
            backgroundColor:"#D2996C",
           
          }}
        >
          <Typography
            variant="h2"
            gutterBottom
            sx={{
              fontFamily: "Playfair Display",
              fontWeight: 500,
              fontSize: {md:"74px",sm:"60px",xs:"50px"},
              color: "#ffffff",
              textAlign: "center",
            }}
          >
            Choose from these Packages
          </Typography>
          <Grid
            container
            spacing={4}
            justifyContent="center"
            textAlign={"center"}
          >
            {cardDataBlack.map((card, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card
                  sx={{
                    maxWidth: 320,
                    height: "700px",
                    backgroundColor: "white",
                   margin:"auto",
                    borderRadius: "0px", // Rounded corners
                    boxShadow: 3, // Add subtle shadow
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontFamily: "DM Sans",
                        fontWeight: 500,
                        fontSize: { xs: "20px", sm: "20px", md: "20px" },
                      }}
                      >
                        {card.title} 
                      </Typography>
                  
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "DM sans",
                        fontWeight: 400,
                        fontSize: "16px",
                        color: "#747576",
                      }}
                    >
                      Package starts from
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      sx={{
                        mb: 2,
                        fontFamily: "DM sans",
                        fontWeight: 500,
                        fontSize: "20px",
                        color: "#747576",
                      }}
                    >
                      {card.price}
                    </Typography>
                    <ViewGallary/>
                  </CardContent>
                  <CardMedia
                    component="img"
                    sx={{ width: "100%",maxWidth:"200px", margin: "0 auto", pb: 2 }}
                    image={card.image}
                    alt={card.title}
                  />
                  <ul
                    style={{
                      paddingBottom: 2,
                      margin: "0 50px",
                      listStyleType: "disc",
                      textAlign: "left",
                      paddingLeft: "20px",
                      fontFamily: "DM Sans",
                      fontWeight: 400,
                      fontSize: "16px",
                      color: "#747576",
                    }}
                  >
                    {card.features.map((feature, idx) => (
                      <li key={idx}>{feature}</li>
                    ))}
                  </ul>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

      </Box>
    </section>
  );
};

export default Packages;
