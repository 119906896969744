import * as React from "react";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Typography } from "@mui/material";
import Frame1 from '../../../assets/images/EcommercePhotosBasic/Frame100.png';
import Frame2 from '../../../assets/images/EcommercePhotosBasic/Frame101.png';
import Frame3 from '../../../assets/images/EcommercePhotosBasic/Frame102.png';
import Frame4 from '../../../assets/images/EcommercePhotosBasic/Frame104.png';
import Frame5 from '../../../assets/images/EcommercePhotosBasic/Frame105.png';
import Frame6 from '../../../assets/images/EcommercePhotosBasic/Frame106.png';
import Frame7 from '../../../assets/images/EcommercePhotosBasic/Frame107.png';
import Frame8 from '../../../assets/images/EcommercePhotosBasic/Frame108.png';
import Frame9 from '../../../assets/images/EcommercePhotosBasic/Frame109.png';
import Frame10 from '../../../assets/images/EcommercePhotosBasic/Frame110.png';
import Frame11 from '../../../assets/images/EcommercePhotosBasic/Frame111.png';


export default function EcommercePremium() {
  return (
    <Box
      sx={{
        px: { xs: 2, sm: 3, md: 8, lg: 8 },
        maxWidth: "1600px",
        mx: "auto",
        py: { xs: 4, md: 8 },
        bgcolor: "#f5f5f5", // Light background color for a modern feel
      }}
    >
      <Typography
        variant="h2"
        sx={{
          color: "grey",
          fontSize: "74px",
          fontFamily: "Playfair Display",
          textAlign: "center",
        }}
      >
        Ecommerce - Premium
      </Typography>
      <Box sx={{ width: "100%", height: "100vh", overflowY: "scroll" }}>
        <ImageList variant="masonry" cols={4} gap={8}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </Box>
  );
}

const itemData = [
  {
    img:Frame1,
    title: "Bed",
  },
  {
    img: Frame2,
    title: "Books",
  },
  {
    img: Frame3,
    title: "Sink",
  },
  {
    img: Frame4,
    title: "Kitchen",
  },
  {
    img: Frame5,
    title: "Blinds",
  },
  {
    img:Frame6,
    title: "Chairs",
  },
  {
    img: Frame7,
    title: "Laptop",
  },
  {
    img: Frame8,
    title: "Doors",
  },
  {
    img: Frame9,
    title: "Coffee",
  },
  
  {
    img: Frame10,
    title: "Coffee",
  },
  {
    img: Frame11,
    title: "Storage",
  },
]
