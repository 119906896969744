import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ViewPackageDetails = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/packages");
  };
  return (
    <Button
      variant="contained"
      onClick={handleNavigate}
      size="large"
      sx={{
        fontFamily: "DM Sans",
        fontSize: "20px",
        fontWeight: 500,
        color: "#ffffff",
        bgcolor: "#D2996C",
        textTransform: "none",
        padding: "20px 50px",
      }}
    >
      View Package Details
    </Button>
  );
};

export default ViewPackageDetails;
