import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItemButton,
  Button,
  Box,
  Typography,
} from "@mui/material";
import ContactButton from "../components/Buttons/ContactButton";
import ViewGallary from "../components/Buttons/ViewGallary";
import CoralFrameLogo from "../assets/images/coralframelogo.png";
import MenuIcon from "@mui/icons-material/Menu";

const Navbar = () => {
  const navItems = [
    { label: "HOME", id: "home", to: "/" },
    { label: "ABOUT US", id: "about", to: "/" },
    { label: "PACKAGES", id: "packages", to: "/packages" },
    { label: "OUR TEAM", id: "team", to: "/team" },
  ];

  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setMobileOpen(false);
    }
  };

  const navigate = useNavigate();

  const handleContactClick = () => {
    if (location.pathname === "/") {
      handleScrollTo("contactSection");
    } else {
      navigate("/contactSection");
    }
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "center",
        width: "250px",
        padding: "20px",
      }}
    >
      <img
        src={CoralFrameLogo}
        alt="Logo"
        style={{
          width: "100%",
          maxWidth: "200px",
          height: "auto",
          marginBottom: "20px",
        }}
      />
      <List>
        {navItems.map(({ label, id, to }) => (
          <ListItemButton
            key={label}
            onClick={() => (id !== "packages" ? handleScrollTo(id) : null)}
            sx={{ justifyContent: "center" }}
          >
            <Typography variant="body1">
              <NavLink
                to={to}
                style={{
                  textDecoration: "none",
                  color: location.pathname === to ? "#000" : "#747576",
                }}
              >
                {label}
              </NavLink>
            </Typography>
          </ListItemButton>
        ))}
        <ListItemButton sx={{ justifyContent: "center" }}>
          <ContactButton onClick={handleContactClick} />
        </ListItemButton>
        <ListItemButton sx={{ justifyContent: "center" }}>
          <ViewGallary onClick={() => handleScrollTo("about")} />
        </ListItemButton>
      </List>
    </Box>
  );

  return (
    <AppBar
      position="sticky"
      style={{
        backgroundColor: "#fff",
        padding: "15px 60px",
        boxShadow: "none",
        borderBottom: "3px solid #EAECE9",
      }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: { xs: "none", sm: "flex" }, gap: "30px" }}>
          {navItems.map(({ label, id, to }) => (
            <Button
              key={label}
              onClick={() => id !== "packages" && handleScrollTo(id)}
              component={NavLink}
              to={to}
              sx={{
                color: location.pathname === to ? "#000" : "#747576",
                fontWeight: 700,
                textDecoration: "none",
                fontSize: { xs: "16px", sm: "16px", md: "16px" }, // Responsive font size
              }}
            >
              {label}
            </Button>
          ))}
        </Box>

        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
          <img
            src={CoralFrameLogo}
            alt="Logo"
            style={{ width: "100%", maxWidth: "150px", height: "auto" }}
          />
        </Box>

        <Box sx={{ display: { xs: "none", sm: "flex" }, gap: "20px" }}>
          <ContactButton onClick={handleContactClick} />
          <ViewGallary onClick={() => handleScrollTo("about")} />
        </Box>

        <Box sx={{ display: { xs: "block", sm: "none" } }}>
          <IconButton onClick={handleDrawerToggle} sx={{ color: "#000" }}>
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>
      <Drawer anchor="right" open={mobileOpen} onClose={handleDrawerToggle}>
        {drawer}
      </Drawer>
    </AppBar>
  );
};

export default Navbar;
