import React, { useState } from "react";
import { Typography, Grid, Box, Button } from "@mui/material";
import AboutFrameOne from "../../assets/images/aboutframeone.png";
import AboutFrameTwo from "../../assets/images/aboutframetwo.png";

const About = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("");
  

  const handleScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setActiveSection(id);
      setMobileOpen(false);
    }
  };

  const handleContactClick = () => {
    handleScrollTo("contactSection");
  };

  return (
    <section id="about">
      <Box
        sx={{
          px: { xs: 2, sm: 3, md: 5, lg: 8 },
          maxWidth: "1600px",
          mx: "auto",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: "60px", sm: "60px", md: "74px" },
            fontWeight: 500,
            fontFamily: "Playfair Display",
            fontStyle: "normal",
            mb: { xs: 2, md: 1 },
          }}
        >
          Know More About Us
        </Typography>

        <Grid container spacing={12}>
          {/* Left Section */}
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              sx={{
                fontFamily: "DM Sans",
                fontWeight: 500,
                fontSize: { xs: "24px", sm: "28px", md: "32px" },
                lineHeight: { xs: "32px", md: "47px" },
              }}
            >
              We do everything,
            </Typography>

            <Typography
              sx={{
                fontFamily: "DM Sans",
                fontWeight: 500,
                fontSize: { xs: "24px", sm: "28px", md: "32px" },
                lineHeight: { xs: "32px", md: "47px" },
                mb: "0.5rem",
              }}
            >
              so you don’t have to.
            </Typography>

            <Typography
              variant="body1"
              sx={{
                fontFamily: "DM Sans",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: { xs: "16px", sm: "16px", md: "16px" },
                lineHeight: "30px",
                color: "#747576",
                mb: "0.5rem",
              }}
            >
              We work with apparel brands, fashion designers, jewelry companies,
              models, and influencers. Our priority is making clients feel
              comfortable and confident before the lens. We will guide you every
              step of the way from creating the mood board of your dreams down
              to the final delivery stages.
            </Typography>

            <Box
              component="img"
              src={AboutFrameOne}
              alt="About Frame One"
              sx={{
                width: "100%",
                height: "auto",
                mt: 3,
              }}
            />
          </Grid>

          {/* Right Section */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            
            }}
          >
            <Box
              component="img"
              src={AboutFrameTwo}
              alt="About Frame Two"
              sx={{
                width: "100%",
                height: "auto",
                marginBottom: { md: "1rem" },
              
              }}
            />

            <Typography
              variant="h4"
              sx={{
                fontFamily: "DM Sans",
                fontWeight: 500,
                fontSize: { xs: "24px", sm: "28px", md: "32px" },
                lineHeight: { xs: "32px", md: "47px" },
                mb: "0.5rem",
              }}
            >
              That’s where we come in.
            </Typography>

            <Typography
              variant="body1"
              sx={{
                fontFamily: "DM Sans",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: { xs: "16px", sm: "16px", md: "16px" },
                lineHeight: "30px",
                color: "#747576",
                mb: "0.5rem",
              }}
            >
              Our photos are heavily influenced by natural elements and raw
              emotions mixed with classic design. We turn your visions into
              reality whether it is to elevate your brand, create content for
              your products, or capture raw moments.
            </Typography>

            <Box
              sx={{
                display: "inline-flex",
                justifyContent: "left",
                alignItems: "left",
                gap: 1,
                mt: 3,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#D2996C",
                  color: "white",
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  fontSize: "16px",
                  paddingX: "2rem",
                  paddingY: "1rem",
                  borderRadius: "5px",
                  boxShadow: 3,
                  "&:hover": {
                    backgroundColor: "#B5894A",
                  },
                }}
                onClick={handleContactClick}
              >
                Contact Now
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </section>
  );
};

export default About;
