import React from "react";
import { Box, Typography, Divider, IconButton, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import OfferImg from "../../../assets/images/offer.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Offers = () => {
  return (
    <Box
      sx={{
        px: { xs: 2, sm: 3, md: 8 },
        maxWidth: "1600px",
        mx: "auto",
        bgcolor: "#000000",
        py: 4,
      }}
    >
      <Grid container spacing={2}>
        {/* Left Section with Text */}
        <Grid item xs={12} md={8} display="flex" alignItems="flex-start">
          <Box mb={2}>
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Playfair Display",
                fontWeight: 700,
                fontSize: { xs: "36px", md: "84px" },
                color: "#ffffff",
                textAlign: "left",
              }}
            >
              Unlock
              <span
                style={{
                  whiteSpace: "nowrap",
                  display: "inline-block",
                  position: "relative",
                }}
              >
                Mega Special
              </span>
            </Typography>

            <Typography
              variant="h2"
              sx={{
                fontFamily: "Playfair Display",
                fontWeight: 700,
                fontSize: { xs: "36px", md: "84px" },
                color: "#ffffff",
                marginBottom: 3,
              }}
            >
              Offers!
            </Typography>

            <Typography
              sx={{
                mt: 2,
                color: "#c0c0c0",
                fontFamily: "DM Sans",
                fontWeight: 400,
                fontSize: { xs: "16px", md: "20px" },
                lineHeight: 1.6,
                width: { xs: "100%", md: "50%" },
              }}
            >
              Get uncompromised and professional quality services within your budget! Customized packages available.
            </Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            <img
              src={OfferImg}
              alt="Offers"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "0px",
                boxShadow: "0 4px 20px rgba(0,0,0,0.3)",
                marginLeft: "-25%",
              }}
            />
            <Button
              variant="contained"
              sx={{
                top: "30%",
                transform: "translate(-50%, 0)",
                bgcolor: "#000000",
                color: "#ffffff",
                fontFamily: "Playfair Display",
                fontWeight: 500,
                fontSize: { xs: "16px", md: "24px" },
                padding: "10px 20px",
                "&:hover": {
                  bgcolor: "#333",
                },
              }}
            >
              Enquire Now
            </Button>
          </Box>
        </Grid>

        {/* Right Section with Offers */}
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              bgcolor: "#333",
              height: "auto",
              padding: { xs: 2, sm: 3, md: 4 },
              borderRadius: "8px",
              boxShadow: "0 4px 20px rgba(0,0,0,0.3)",
            }}
          >
            {/* Offer Items */}
            {[{
              title: "First Client",
              subtitle: "Photoshoot Offer",
              discount: "10% OFF",
            },
            {
              title: "Independence Day",
              subtitle: "Commercial Offer",
              discount: "40% OFF",
            },
            {
              title: "Exclusive Discount",
              subtitle: "On Studio Access",
              discount: "30% OFF",
            }].map((offer, index) => (
              <Box key={index}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Box sx={{ flex: 1, textAlign: 'left' }}>
                    <Typography
                      sx={{
                        color: "#ffffff",
                        fontFamily: "DM Sans",
                        fontWeight: 500,
                        fontSize: { xs: "24px", md: "36px" },
                      }}
                    >
                      {offer.title}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#ffffff",
                        fontFamily: "DM Sans",
                        fontWeight: 500,
                        fontSize: { xs: "24px", md: "36px" },
                      }}
                    >
                      {offer.subtitle}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#747576",
                        fontFamily: "DM Sans",
                        fontWeight: 700,
                        fontSize: { xs: "18px", md: "20px" },
                      }}
                    >
                      With {offer.discount}
                    </Typography>
                  </Box>
                  <IconButton size="small" sx={{ color: "#ffffff" }}>
                    <ArrowForwardIcon fontSize="inherit" />
                  </IconButton>
                </Box>
                {/* Add Divider except for the last item */}
                {index < 2 && <Divider sx={{ my: 1, bgcolor: "#ffffff" }} />}
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Offers;
